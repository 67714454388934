@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: 'Clash Display', sans-serif;
  /* font-family: "Lato", sans-serif; */
  /* font-family: "Montserrat", sans-serif; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .background-cassaforte{
  background-image: url('./assets/minimalSection/cassaforte.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.background-cassaforte{
  background-image: url('./assets/minimalSection/cassaforte.jpg');
  background-size: cover;
  background-repeat: no-repeat;
} */

.progress-button-light {
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
}

.progress-button-light::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgb(248 250 252); 
  transition: width 0.5s ease;
  z-index: -1;
}

.progress-button-light:hover::after {
  width: 100%;
}

.progress-button-light:hover {
  color: black; /* text-black equivalent */
  z-index: 1;
}

.progress-button-dark {
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
}

.progress-button-dark::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgb(0, 0, 0); 
  transition: width 0.5s ease;
  z-index: -1;
}

.progress-button-dark:hover::after {
  width: 100%;
}

.progress-button-dark:hover {
  color: rgb(255, 255, 255); /* text-black equivalent */
  z-index: 1;
}

.Smoke {
  text-align: center;
}

canvas {
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.link-hidden {
  opacity: 0;
  transition: ease-in-out;
}

.text-negative {
  color: black;
  display: block;
  filter: invert(1);
  mix-blend-mode: difference;
}

.text-negative-remove {
  color: black;
  display: block;
  filter: invert(0)!important;
  mix-blend-mode: normal !important;
}

/* CSS animation for opacity transition */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* CSS animation for height transition */
@keyframes expandHeight {
  0% {
    height: 0;
  }
  100% {
    height: 12rem;
  }
}

/* CSS classes for applying animations */
.opacity-transition {
  opacity: 1;
  animation: fadeIn 4s ease-in-out;
}

.height-transition {
  height: 12rem;
  animation: expandHeight 3s ease-in-out;
}
